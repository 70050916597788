@import "@/assets/css/prepend.scss";
@import './global.scss';
@mixin transform_rotate($val) {
	-webkit-transform: rotate($val);
	-moz-transform: rotate($val);
	-o-transform: rotate($val);
	transform: rotate($val);
}

@mixin transition($val) {
    -webkit-transition: $val;
    -moz-transition: $val;
    -o-transition: $val;
    transition: $val;
}
body,html{
    min-height: 100vh;
    overscroll-behavior: none;
}
html{
    background: #000000;
} 
body{
	direction: ltr;
	background: #000000;
    color: #333333;
    overflow-x: hidden;
    font-family: 'Steinbeck','Arial',sans-serif;
    font-style: normal;
    font-weight: 400;
}
section{
	display:block;
}
// @-webkit-keyframes grain{
//     0%{-webkit-transform:translate(0);transform:translate(0)}
//     10%{-webkit-transform:translate(-5%,-10%);transform:translate(-5%,-10%)}
//     20%{-webkit-transform:translate(-15%,5%);transform:translate(-15%,5%)}
//     30%{-webkit-transform:translate(7%,-25%);transform:translate(7%,-25%)}
//     40%{-webkit-transform:translate(-5%,25%);transform:translate(-5%,25%)}
//     50%{-webkit-transform:translate(-15%,10%);transform:translate(-15%,10%)}
//     60%{-webkit-transform:translate(15%);transform:translate(15%)}
//     70%{-webkit-transform:translateY(15%);transform:translateY(15%)}
//     80%{-webkit-transform:translate(3%,35%);transform:translate(3%,35%)}
//     to{-webkit-transform:translate(-10%,10%);transform:translate(-10%,10%)}
// }
// @-o-keyframes grain{
//     0%{-o-transform:translate(0);transform:translate(0)}
//     10%{-o-transform:translate(-5%,-10%);transform:translate(-5%,-10%)}
//     20%{-o-transform:translate(-15%,5%);transform:translate(-15%,5%)}
//     30%{-o-transform:translate(7%,-25%);transform:translate(7%,-25%)}
//     40%{-o-transform:translate(-5%,25%);transform:translate(-5%,25%)}
//     50%{-o-transform:translate(-15%,10%);transform:translate(-15%,10%)}
//     60%{-o-transform:translate(15%);transform:translate(15%)}
//     70%{-o-transform:translateY(15%);transform:translateY(15%)}
//     80%{-o-transform:translate(3%,35%);transform:translate(3%,35%)}
//     to{-o-transform:translate(-10%,10%);transform:translate(-10%,10%)}
// }
// @keyframes grain{
//     0%{-webkit-transform:translate(0);-o-transform:translate(0);transform:translate(0)}
//     10%{-webkit-transform:translate(-5%,-10%);-o-transform:translate(-5%,-10%);transform:translate(-5%,-10%)}
//     20%{-webkit-transform:translate(-15%,5%);-o-transform:translate(-15%,5%);transform:translate(-15%,5%)}
//     30%{-webkit-transform:translate(7%,-25%);-o-transform:translate(7%,-25%);transform:translate(7%,-25%)}
//     40%{-webkit-transform:translate(-5%,25%);-o-transform:translate(-5%,25%);transform:translate(-5%,25%)}
//     50%{-webkit-transform:translate(-15%,10%);-o-transform:translate(-15%,10%);transform:translate(-15%,10%)}
//     60%{-webkit-transform:translate(15%);-o-transform:translate(15%);transform:translate(15%)}
//     70%{-webkit-transform:translateY(15%);-o-transform:translateY(15%);transform:translateY(15%)}
//     80%{-webkit-transform:translate(3%,35%);-o-transform:translate(3%,35%);transform:translate(3%,35%)}
//     to{-webkit-transform:translate(-10%,10%);-o-transform:translate(-10%,10%);transform:translate(-10%,10%)}
// }
// @keyframes rotateBtn {
//     0% {
//       transform: rotate(0deg);
//     }
//     50% {
//       transform: rotate(-180deg);
//     }
//     100% {
//       transform: rotate(-360deg);
//     }
// }
// @keyframes rotateBtnRevers {
//     0% {
//       transform: rotate(0deg);
//     }
//     50% {
//       transform: rotate(180deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
// }
// @keyframes blink {
//     0% {
//         opacity: 0;
//     }
//     40% {
//         opacity: 1;
//     }
//     60% {
//         opacity: 1;
//     }
//     100% {
//         opacity: 0;
//     }
// }