@import "@/assets/css/prepend.scss";
@import './global.scss';
$letterSpacingD: -0.0416667vw;
$letterSpacingM: -0.104vw;

.soD {
  @include mob {
    display: none !important;
  }
}
.soM {
  display: none !important;
  @include mob {
    display: block !important;
  }
}

.pinkbg {
  background: #f9dddd;
}

.pinkbg2 {
  background: #f68a8a;
}

section.home {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100vh;
  max-height: $d810px;
  .gradient1 {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    height: $d262px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.71) 0%, rgba(0, 0, 0, 0) 55.96%);
    @include mob {
      height: $m215px;
    }
  }
  .gradient2 {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    height: $d193px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 55.96%);
    @include mob {
      height: $m158px;
    }
  }
  @include mob {
    max-height: $m667px;
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url(../images/homeBg.jpg);
    @include mob {
      background-image: url(../images/homeBgMob.jpg);
    }
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    video {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      // opacity: 0;
      // &.ready{
      //   opacity: 1;
      // }
    }
  }
  .logo {
    position: absolute;
    // top: -$d163px;
    top: $d26px;
    left: $d23px;
    width: $d1386px;
    // @include transition(all 1.5s linear);
    img {
      width: 100%;
    }
    @include mob {
      top: $m14px;
      left: $m15px;
      width: $m345px;
    }
    // &.ready{
    //   top: $d26px;
    //   @include mob{
    //     top: $m14px;
    //   }
    // }
  }
  .desc {
    position: absolute;
    bottom: $d63px;
    left: $d156px;
    width: $d1128px;

    color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'Heading Now';
    font-size: $d20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: $letterSpacingD;
    @include mob {
      bottom: $m46px;
      left: $m15px;
      width: $m345px;
      font-size: $m13px;
      letter-spacing: $letterSpacingM;
    }
    .dLine {
      overflow: hidden;
      .dLineContent {
        @include transition(all 1s linear);
        transform: translate3d(0px, 180%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
          skew(0deg, 0deg);
        transform-style: preserve-3d;
      }
    }
    &.ready {
      .dLine {
        .dLineContent {
          transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
            skew(0deg, 0deg);
        }
      }
    }
  }
}
section.about {
  position: relative;
  z-index: 3;
  width: 100%;
  border-radius: $d47px;
  background: #f9dddd;
  @include mob {
    border-radius: $m33px;
    margin-top: -$m27px;
  }
  .gradient {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    top: $d589px;
    width: 100%;
    border-radius: 0 0 $d47px $d47px;
    background: linear-gradient(180deg, rgba(249, 221, 221, 0.35) 0%, rgba(244, 102, 102, 0.35) 67.19%);
    @include mob {
      border-radius: 0 0 $m33px $m33px;
      top: $m520px;
    }
  }
  .container {
    position: relative;
    z-index: 1;
    padding: $d40px 0 $d44px;
    @include mob {
      padding: $m40px 0;
    }
    h2 {
      width: $d1012px;
      margin: $d55px auto;
      @include mob {
        margin: $m8px auto $m30px;
      }

      color: #000;
      text-align: center;
      font-family: 'Heading Now';
      font-size: $d20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: $letterSpacingD;
      text-transform: uppercase;
      @include mob {
        width: $m222px;
        font-size: $m13px;
        letter-spacing: $letterSpacingM;
      }
    }
    .crystal {
      width: $d212px;
      height: $d174px;
      margin: $d50px auto;
      @include mob {
        width: $m166px;
        height: $m136px;
        margin: $m30px auto;
      }
      .object {
        width: $d212px;
        height: $d174px;
        @include mob {
          width: $m166px;
          height: $m136px;
        }
        background-image: url(../images/crystal.png);
        background-repeat: no-repeat;
        background-position: top left;
        background-size: contain;
        @include transition(all 0.3s ease-in-out);
        animation: jitter 3s infinite linear;
      }
    }
    .article {
      width: $d1039px;
      // height: $d380px;
      margin: 0 auto;

      @include desk {
        padding-bottom: $d80px;
      }
      @include mob {
        width: $m320px;
        height: auto;
        margin-bottom: $m51px;
      }

      color: #000;
      text-align: center;
      font-size: $d25px;
      font-style: normal;
      font-weight: 400;
      line-height: $d34px;
      letter-spacing: 0.03472222222vw;
      text-transform: uppercase;
      a {
        color: #000;
      }
      @include mob {
        font-size: $m16px;
        line-height: $m21px;
        letter-spacing: 0.08533333333vw;
      }
    }
    .goals {
      margin-bottom: $d81px;
      @include mob {
        margin-bottom: $m60px;
      }
      .item {
        height: $d141px;
        @include mob {
          height: auto;
        }
        & + .item {
          border-top: 1px solid #f46666;
        }
        .raw {
          position: absolute;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          flex-direction: row;
          align-items: center;
          height: $d141px;
          left: $d104px;
          right: $d131px;
          max-width: 100%;
          @include mob {
            display: block;
            position: relative;
            height: auto;
            left: 0;
            right: 0;

            padding: $m13px $m15px $m35px;
          }
          .n {
            width: $d38px;
            min-width: $d38px;

            color: #f46666;
            text-align: left;
            font-family: 'Noto Serif Display';
            font-size: $d23px;
            font-style: normal;
            font-weight: 400;
            line-height: $d34px;
            letter-spacing: -0.04791666667vw;
            text-transform: uppercase;

            @include mob {
              width: 100%;
              min-width: 100%;
              font-size: $m18px;
              line-height: $m34px;
              letter-spacing: -0.144vw;
              margin-bottom: $m14px;
            }
          }
          .title {
            width: $d268px;
            min-width: $d268px;

            color: #000;
            font-family: 'Heading Now';
            font-size: $d28px;
            font-style: normal;
            font-weight: 400;
            line-height: $d34px; /* 121.429% */
            letter-spacing: 0.03888888889vw;
            text-transform: uppercase;

            @include mob {
              width: 100%;
              min-width: 100%;
              font-size: $m17px;
              line-height: $m21px;
              letter-spacing: 0.09066666667vw;
              margin-bottom: $m14px;
            }
          }
          .desc {
            -webkit-flex: auto;
            flex: auto;

            color: #000;
            font-family: Steinbeck;
            font-size: $d17px;
            font-style: normal;
            font-weight: 400;
            line-height: $d24px; /* 141.176% */
            letter-spacing: -0.02361111111vw;
            text-transform: uppercase;

            @include mob {
              width: 100%;
              min-width: 100%;
              font-size: $m12px;
              line-height: $m19px;
              letter-spacing: 0.064vw;
            }
          }
        }
      }
    }
    #marquee {
      img {
        width: $d1396px;
        padding-right: $d88px;
        @include mob {
          width: $m662px;
          height: $m65px;
        }
      }
    }
  }
}
@keyframes jitter {
  0% {
    @include transform_rotate(0deg);
  }
  40% {
    @include transform_rotate(20deg);
  }
  45% {
    @include transform_rotate(36deg);
  }
  50% {
    @include transform_rotate(-14deg);
  }
  57% {
    @include transform_rotate(10deg);
  }
  63% {
    @include transform_rotate(-8deg);
  }
  68% {
    @include transform_rotate(0deg);
  }
  100% {
    @include transform_rotate(0deg);
  }
}
section.cases {
  position: relative;
  z-index: 2;
  bottom: 0;
  margin-top: -$d36px;
  width: 100%;
  min-height: 100vh;
  background: #000;

  @include mob {
    margin-top: -$m30px;
  }
  .boxes {
    position: relative;
    z-index: 1;
    margin-top: -100vh;
    .box {
      width: 100%;
      height: 100vw;
      &:last-child {
        height: 100vh;
      }
    }
  }
  .wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 100vh;
    overflow: hidden;
    .viewport {
      position: absolute;
      top: 0;
      height: 100vh;
      display: flex;

      .case {
        position: relative;
        width: 100vw;
        height: 100vh;
        background: #6e6c6c;
        .bg {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          overflow: hidden;
          opacity: 0.82;
          pointer-events: none;
          background-repeat: no-repeat;
          background-position: top left;
          background-size: cover;
          video {
            -o-object-fit: cover;
            object-fit: cover;
            @media (aspect-ratio: 16/9) {
              width: 100%;
            }
            @media (max-aspect-ratio: 16/9) {
              height: 100%;
            }
            @media (min-aspect-ratio: 16/9) {
              width: 100%;
            }
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            // opacity: 0;
          }
          .blur {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            backdrop-filter: blur(5px);
            opacity: 1;
          }
          &.ready {
            .blur {
              opacity: 0;
            }
            // video{
            //   opacity: 1;
            // }
          }
        }
        .gradient {
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          height: $d318px;
          width: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.59) 55.96%);
          @include mob {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.19) 55.96%);
            height: $m266px;
          }
        }
        .container {
          position: absolute;
          z-index: 2;
          top: $d104px;
          left: $d114px;
          right: $d750px;
          bottom: $d88px;

          @include mob {
            top: $m60px;
            left: $m15px;
            right: $m48px;
            bottom: $m50px;
          }
          h2 {
            color: #fff;
            font-family: 'Heading Now';
            font-size: $d30px;
            font-style: normal;
            font-weight: 400;
            line-height: $d34px;
            letter-spacing: 0.04166666667vw;
            text-transform: uppercase;

            margin-bottom: $d15px;

            @include mob {
              font-size: $m13px;
              line-height: normal;
              letter-spacing: 0.06933333333vw;
              margin-bottom: $m9px;
            }
          }
          h3 {
            color: #fff;
            font-family: Steinbeck;
            font-size: $d20px;
            font-style: normal;
            font-weight: 400;
            line-height: $d27px;
            text-transform: uppercase;

            margin-bottom: 0;

            @include mob {
              font-size: $m12px;
              line-height: $m17px;
            }
            a {
              color: #fff;
              text-decoration: underline;
              cursor: pointer;
            }
          }
          h4 {
            color: #fff;
            font-family: 'Noto Serif Display';
            font-size: $d20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.04166666667vw;
            text-transform: uppercase;

            margin-bottom: $d15px;

            @include mob {
              font-size: $m18px;
              letter-spacing: -0.144vw;
              margin-bottom: $m9px;
            }
          }

          .desc {
            position: absolute;
            bottom: $d38px;
            left: 0;
            width: $d441px;

            color: #fff;
            font-family: Steinbeck;
            font-size: $d17px;
            font-style: normal;
            font-weight: 400;
            line-height: $d22px; /* 129.412% */
            letter-spacing: 0.02361111111vw;

            @include mob {
              bottom: $m27px;
              width: $m299px;
              font-size: $m13px;
              line-height: $m15px;
              letter-spacing: 0.06933333333vw;
            }
          }
          .more {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;

            color: #f46666;
            text-align: center;
            font-family: 'Heading Now';
            font-size: $d20px;
            font-style: normal;
            font-weight: 400;
            line-height: $d26px;
            letter-spacing: 0.04166666667vw;
            text-transform: uppercase;
            text-decoration: none;

            cursor: pointer;
            &:hover {
              color: #fff;
            }
            @include mob {
              font-size: $m13px;
              line-height: normal;
              letter-spacing: -0.104vw;
            }
          }
        }
        &.case1 {
          .bg {
            background-image: url(../images/cases/case1.jpg);
            @include mob {
              background-image: url(../images/cases/case1_mob.jpg);
            }
          }
        }
        &.case2 {
          .bg {
            background-image: url(../images/cases/case2.jpg);
            @include mob {
              background-image: url(../images/cases/case2_mob.jpg);
            }
          }
          .desc {
            @include desk {
              width: $d454px;
            }
          }
          .gradient {
            @include desk {
              height: $d455px;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 55.96%);
            }
          }
        }
        &.case3 {
          .bg {
            background-image: url(../images/cases/case3.jpg);
            @include mob {
              background-image: url(../images/cases/case3_mob.jpg);
            }
          }
          .desc {
            @include desk {
              width: $d464px;
            }
          }
        }
        &.case4 {
          .bg {
            background-image: url(../images/cases/case4.jpg);
            @include mob {
              background-image: url(../images/cases/case4_mob.jpg);
            }
          }
          .desc {
            @include desk {
              width: $d464px;
            }
          }
        }
      }
    }
  }
}
.modal {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding-bottom: $d37px;
  background-color: rgba(0, 0, 0, 0.438);
  @include mob {
    padding-bottom: 0;
  }
  .panel {
    position: relative;
    min-height: calc(100vh - 2.70833333333vw - 2.56944444444vw);
    margin: $d39px $d37px 0 $d37px;
    border-radius: $d47px;
    background: #f9dddd;
    @include mob {
      min-height: calc(100vh - 8.53333333333vw - 8.53333333333vw);
      margin: $m32px $m15px 0 $m15px;
      border-radius: $m33px;
    }

    .gradient {
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      top: $d353px;
      width: 100%;
      border-radius: 0 0 $d47px $d47px;
      background: linear-gradient(180deg, rgba(249, 221, 221, 0.35) 0%, rgba(244, 102, 102, 0.35) 67.19%);
      @include mob {
        border-radius: 0 0 $m33px $m33px;
        top: $m188px;
      }
    }
    .close {
      position: absolute;
      right: $d22px;
      top: $d22px;
      z-index: 4;
      width: $d37px;
      height: $d37px;
      background-image: url(../images/close.svg);
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;

      cursor: pointer;
      @include mob {
        right: $m8px;
        top: $m12px;
        width: $m31px;
        height: $m31px;
      }
    }
    .container {
      position: relative;
      padding-top: $d70px;
      padding-bottom: $d55px;
      @include mob {
        padding-top: $m100px;
        padding-bottom: $m100px;
      }
      h2 {
        color: #000;
        text-align: center;
        font-family: 'Heading Now';
        font-size: $d20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.04166666667vw;
        text-transform: uppercase;
        margin-bottom: $d44px;
        @include mob {
          font-size: $m13px;
          letter-spacing: 0.06933333333vw;
          margin-bottom: $m25px;
        }
      }

      .slick-slider {
        position: relative;
        display: block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;

        .slick-list {
          position: relative;
          z-index: 5;
          display: block;
          overflow: hidden;
          margin: 0;
          padding: 0;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          &:focus {
            outline: none;
          }
          &.dragging {
            cursor: pointer;
            cursor: hand;
          }

          .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            &.slick-center {
              margin-left: auto;
              margin-right: auto;
            }
            &:after,
            &:before {
              display: table;
              content: '';
            }
            &:after {
              clear: both;
            }

            .slick-slide {
              display: none;
              float: left;
              height: 100%;
              min-height: 1px;
              &:nth-child(odd) {
                .card {
                  padding-left: $d83px;
                  @include mob {
                    padding-left: 0;
                  }
                }
              }
              &:nth-child(even) {
                .card {
                  padding-right: $d83px;
                  @include mob {
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
        &.slick-initialized .slick-list .slick-track .slick-slide {
          display: block;
        }

        .slick-prev,
        .slick-next {
          font-size: 0;
          line-height: 0;

          position: absolute;
          z-index: 6;
          top: $d115px;

          display: block;

          width: $d46px;
          height: $d30px;
          padding: 0;
          @include mob {
            top: auto;
            bottom: -$m75px;
            width: $m39px;
            height: $m25px;
          }

          cursor: pointer;

          color: transparent;
          border: none;
          outline: none;
          background: transparent;

          background-repeat: no-repeat;
          background-position: top left;
          background-size: contain;
          opacity: 1;
          @include transition(all 0.3s ease-in-out);
          &.slick-disabled {
            opacity: 0;
            pointer-events: none;
          }
        }
        .slick-prev {
          background-image: url(../images/prev.svg);
          left: $d62px;
          @include mob {
            left: $m26px;
          }
        }
        .slick-next {
          background-image: url(../images/next.svg);
          right: $d62px;
          @include mob {
            right: $m26px;
          }
        }
      }

      .card {
        .row {
          width: $d496px;
          margin: 0 auto;
          @include mob {
            width: $m293px;
          }
          img,
          video {
            width: 100%;
          }
          iframe {
            width: $d500px;
            height: $d270px;
            @include mob {
              width: $m293px;
              height: $m159px;
            }
          }
          .content {
            margin-top: $d49px;
            @include mob {
              margin-top: $m26px;
            }
            .title {
              color: #f46666;
              font-family: Heading Now;
              font-size: $d17px;
              font-style: normal;
              font-weight: 400;
              line-height: $d21px;
              letter-spacing: 0.02361111111vw;
              text-transform: uppercase;
              margin-bottom: $d36px;
              @include mob {
                font-size: $m15px;
                font-style: normal;
                font-weight: 400;
                line-height: $m21px;
                letter-spacing: 0.08vw;
                text-transform: uppercase;
                margin-bottom: $m30px;
              }
              span {
                font-family: 'Noto Serif Display';
              }
            }
            .desc {
              color: #000;
              font-family: Steinbeck;
              font-size: $d17px;
              font-style: normal;
              font-weight: 400;
              line-height: $d24px;
              letter-spacing: 0.02361111111vw;
              @include mob {
                font-size: $m13px;
                line-height: $m15px;
                letter-spacing: 0.06933333333vw;
              }
            }
          }
        }
      }
    }
  }
}
section.formats {
  margin-top: -$d36px;

  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: $d47px;
  background: #f9dddd;
  overflow: hidden;
  @include mob {
    border-radius: $m33px;
    margin-top: -$m27px;
    padding-bottom: $m18px;
  }
  .gradient {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    top: $d661px;
    width: 100%;
    border-radius: 0 0 $d47px $d47px;
    background: linear-gradient(180deg, rgba(249, 221, 221, 0.35) 0%, rgba(244, 102, 102, 0.35) 67.19%);
    @include mob {
      border-radius: 0 0 $m33px $m33px;
      top: $m563px;
    }
  }
  .container {
    position: relative;
    z-index: 1;
    padding-top: $d95px;
    @include mob {
      padding-top: $m48px;
    }
    h2 {
      margin-bottom: $d39px;
      @include mob {
        margin-bottom: $m27px;
      }

      color: #000;
      text-align: center;
      font-family: 'Heading Now';
      font-size: $d20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: $letterSpacingD;
      text-transform: uppercase;
      @include mob {
        font-size: $m13px;
        letter-spacing: $letterSpacingM;
      }
    }
    .list {
      @include mob {
        padding: 0 $m15px;
      }
      .item {
        position: relative;
        height: $d126px;
        border-top: 1px solid #f46666;
        overflow: hidden;
        width: 100%;
        @include mob {
          height: auto;
          border: 1px solid #f46666;
          border-radius: $m19px;
        }
        & + .item {
          @include mob {
            margin-top: $m12px;
          }
        }
        .raw {
          position: absolute;
          z-index: 1;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          flex-direction: row;
          align-items: center;
          height: $d126px;
          left: $d74px;
          right: $d74px;
          max-width: 100%;
          @include mob {
            display: block;
            position: relative;
            height: auto;
            left: 0;
            right: 0;

            padding: $m6px $m15px $m16px;
          }
          .n {
            width: $d42px;
            min-width: $d42px;

            color: #f46666;
            text-align: left;
            font-family: 'Noto Serif Display';
            font-size: $d23px;
            font-style: normal;
            font-weight: 400;
            line-height: $d34px;
            letter-spacing: -0.04791666667vw;
            text-transform: uppercase;

            @include mob {
              width: 100%;
              min-width: 100%;
              font-size: $m18px;
              line-height: $m34px;
              letter-spacing: -0.144vw;
              margin-bottom: $m27px;
            }
          }
          .title {
            width: $d670px;
            min-width: $d670px;

            color: #000;
            font-family: 'Heading Now';
            font-size: $d24px;
            font-style: normal;
            font-weight: 400;
            line-height: $d34px; /* 121.429% */
            letter-spacing: -0.05vw;
            text-transform: uppercase;

            @include mob {
              width: 100%;
              min-width: 100%;
              font-size: $m17px;
              line-height: $m21px;
              letter-spacing: 0.09066666667vw;
              margin-bottom: $m9px;
            }
          }
          .desc {
            -webkit-flex: auto;
            flex: auto;

            color: #000;
            font-family: Steinbeck;
            font-size: $d18px;
            font-style: normal;
            font-weight: 400;
            line-height: $d19px; /* 141.176% */
            letter-spacing: 0.025vw;

            @include mob {
              width: 100%;
              min-width: 100%;
              font-size: $m13px;
              line-height: $m15px;
              letter-spacing: 0.064vw;
            }
          }
        }
        .wrap {
          @include mob {
            display: none;
          }
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100vw;
          background: linear-gradient(180deg, rgba(244, 102, 102, 0.89) 0%, rgba(249, 221, 221, 0) 67.19%);
          opacity: 0;
          cursor: pointer;
          .viewport {
            position: relative;
            height: 100%;
            display: -webkit-flex;
            display: flex;
            flex-wrap: nowrap;
            -webkit-flex-direction: row;
            flex-direction: row;
            align-items: center;
            gap: $d21px;
            img {
              height: $d99px;
            }
            .title {
              margin-top: -$d13px;
              img {
                height: $d50px;
                margin-left: $d36px;
                margin-right: $d39px;
                &.fd {
                  height: $d56px;
                }
                &.fu {
                  height: $d59px;
                  margin-top: -$d9px;
                }
                &.fud {
                  height: $d66px;
                  margin-top: -$d9px;
                }
              }
            }
            .refs {
              position: absolute;
              left: $d35px;
              top: $d80px;

              color: #f46666;
              font-family: 'Heading Now';
              font-size: $d20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.04166666667vw;
            }
          }
        }
        @include desk {
          &:hover {
            .raw {
              opacity: 0;
            }
            .wrap {
              opacity: 1;
              .viewport {
                animation: ticker 5s linear forwards;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes ticker {
  0% {
    opacity: 0;
    transform: translate(0);
  }
  10% {
    opacity: 1;
  }
  to {
    transform: translate(-$d390px);
  }
}

section.persons {
  .boxes {
    position: relative;
    z-index: 1;
    margin-top: -100vh;
    .boxMob {
      display: none;
      width: 100%;
      height: 100vw;
      @include mob {
        display: block;
      }
    }
    .box {
      width: 100%;
      height: 100vw;
      &.boxHalf {
        height: 50vw;
      }
      &:last-child {
        height: 100vh;
      }
    }
  }
  .wrapper {
    background: #f9dddd;
    .gradient {
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 0;
      top: $d205px;
      width: 100%;
      background: linear-gradient(180deg, rgba(249, 221, 221, 0.7) 0%, rgba(244, 102, 102, 0.7) 67.19%);
      @include mob {
        top: $m227px;
      }
    }
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 100vh;
    overflow: hidden;
    .viewport {
      position: absolute;
      top: 0;
      height: 100vh;
      display: flex;

      .personpage {
        position: relative;
        width: 50vw;
        height: 100vh;
        @include mob {
          width: 100vw;
          overflow: hidden;
        }
        h3 {
          position: absolute;
          left: $d70px;
          top: $d56px;

          color: #000;
          font-family: 'Heading Now';
          font-size: $d20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.04166666667vw;
          text-transform: uppercase;
          @include mob {
            left: $m17px;
            top: $m27px;
            font-size: $m13px;
            letter-spacing: -0.104vw;
          }
        }
        @include desk {
          &:nth-child(even) {
            h3 {
              display: none;
            }
          }
        }
        .person {
          position: absolute;
          background-repeat: no-repeat;
          background-position: bottom left;
          background-size: contain;
        }
        .person1 {
          background-image: url(../images/persons/1.png);
          width: $d693px;
          height: $d768px;
          left: $d20px;
          bottom: -$d32px;
          @include mob {
            transform: rotate(-2deg);
            width: $m328px;
            height: $m359px;
            left: $m12px;
            bottom: $m215px;
          }
        }
        .person2 {
          background-image: url(../images/persons/2.png);
          width: $d705px;
          height: $d780px;
          right: 0;
          bottom: 0;
          @include mob {
            width: $m327px;
            height: $m362px;
            right: $m19px;
            bottom: $m239px;
          }
        }
        .person3 {
          background-image: url(../images/persons/3.png);
          width: $d777px;
          height: $d775px;

          left: -$d30px;
          bottom: -$d34px;
          @include mob {
            width: $m367px;
            height: $m359px;
            left: 0;
            bottom: $m208px;
          }
        }
        .person4 {
          background-image: url(../images/persons/4.png);
          width: $d680px;
          height: $d753px;
          right: $d22px;
          bottom: -$d17px;
          @include mob {
            width: $m323px;
            height: $m357px;
            right: $m26px;
            bottom: $m211px;
          }
        }
        .person5 {
          background-image: url(../images/persons/5.png);
          width: $d810px;
          height: $d797px;
          left: 0;
          bottom: 0;
          @include mob {
            width: $m380px;
            height: $m575px;
            left: $m12px;
            bottom: $m216px;
            transform: rotate(2deg);
          }
        }
        .person6 {
          background-image: url(../images/persons/6.png);
          width: $d860px;
          height: $d797px;
          right: 0;
          bottom: 0;
          @include mob {
            background-image: url(../images/persons/6m.png);
            width: $m407px;
            height: $m393px;
            right: -$m22px;
            bottom: $m195px;
          }
        }
        .person7 {
          background-image: url(../images/persons/7.png);
          width: $d921px;
          height: $d748px;
          left: -$d85px;
          bottom: -$d20px;
          @include mob {
            width: $m427px;
            height: $m346px;
            transform: rotate(-3deg);
            left: -$m47px;
            bottom: $m228px;
          }
        }
        .person8 {
          background-image: url(../images/persons/8.png);
          width: $d750px;
          height: $d748px;
          right: -$d23px;
          bottom: -$d20px;
          @include mob {
            width: $m352px;
            height: $m351px;
            transform: rotate(-3.94deg);
            right: $m20px;
            bottom: $m222px;
          }
        }
        .person9 {
          background-image: url(../images/persons/9.png);
          width: $d848px;
          height: $d849px;
          left: -$d26px;
          bottom: -$d62px;
          @include mob {
            width: $m393px;
            height: $m394px;

            right: $m9px;
            bottom: $m208px;
          }
        }
        .person10 {
          background-image: url(../images/persons/10.png);
          z-index: 5;
          width: $d801px;
          height: $d781px;
          right: -$d81px;
          bottom: 0;
          @include mob {
            width: $m357px;
            height: $m357px;
            right: $m1px;
            bottom: $m234px;
          }
        }
        .person11 {
          background-image: url(../images/persons/11.png);
          z-index: 5;
          width: $d771px;
          height: $d718px;
          left: -$d13px;
          bottom: -$d1px;
          @include mob {
            width: $m369px;
            height: $m344px;
            left: $m3px;
            bottom: $m230px;
            transform: scale(-1, 1);
          }
        }
        .person12 {
          background-image: url(../images/persons/12.png);
          width: $d876px;
          height: $d818px;
          right: -$d1px;
          bottom: -$d90px;
          @include mob {
            width: $m381px;
            height: $m356px;
            transform: rotate(-3deg);

            right: $m8px;
            bottom: $m205px;
          }
        }
        .person13 {
          background-image: url(../images/persons/13.png);
          z-index: 5;
          width: $d754px;
          height: $d767px;
          left: -$d10px;
          bottom: -$d47px;
          @include mob {
            width: $m337px;
            height: $m343px;
            left: $m18px;
            bottom: $m232px;
          }
        }
        .person14 {
          background-image: url(../images/persons/14.png);
          width: $d839px;
          height: $d823px;
          right: -$d22px;
          bottom: -$d53px;
          @include mob {
            width: $m381px;
            height: $m373px;
            right: -$m4px;
            bottom: $m219px;
          }
        }
        .person15 {
          background-image: url(../images/persons/15.png);
          width: $d824px;
          height: $d829px;
          transform: rotate(6deg);
          left: $d44px;
          bottom: -$d44px;
          @include mob {
            width: $m349px;
            height: $m351px;
            transform: rotate(4deg);
            left: $m28px;
            bottom: $m225px;
          }
        }
        .person16 {
          background-image: url(../images/persons/16.png);
          width: $d609px;
          height: $d799px;
          right: $d37px;
          bottom: -$d13px;
          @include mob {
            width: $m272px;
            height: $m355px;
            right: $m51px;
            bottom: $m241px;
          }
        }
        .info-panels {
          position: absolute;
          z-index: 10;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          .info-panel {
            position: relative;
            min-width: 100%;
            height: $d204px;
            padding: $d36px $d95px 0 $d70px;
            @include mob {
              height: $m244px;
              padding: $m28px $m17px 0 $m17px;
              background: #fff;
              border-radius: $m33px $m33px 0px 0px;
            }
            h2 {
              color: #fff;
              font-family: 'Heading Now';
              font-size: $d25px;
              font-style: normal;
              font-weight: 400;
              line-height: $d34px; /* 136% */
              letter-spacing: 0.03472222222vw;
              text-transform: uppercase;
              margin-bottom: $d14px;
              @include mob {
                color: #000;
                font-size: $m17px;
                line-height: $m22px; /* 129.412% */
                letter-spacing: 0.09066666667vw;
                text-transform: uppercase;
                margin-bottom: $m4px;
              }
            }
            .desc {
              color: #fff;
              font-family: Steinbeck;
              font-size: $d17px;
              font-style: normal;
              font-weight: 400;
              line-height: $d24px;
              text-transform: uppercase;
              @include mob {
                color: #000;
                font-size: $m13px;
                line-height: $m22px;
                margin-bottom: $m14px;
              }
            }

            .bio {
              color: #000;
              font-family: Steinbeck;
              font-size: $d17px;
              font-style: normal;
              font-weight: 400;
              line-height: $d22px;
              @include desk {
                position: absolute;
                left: 0;
                right: 0;
                padding: $d49px $d56px 0;
                height: $d204px;
                top: 100%;
                border-radius: $d46px $d46px 0px 0px;
                background: #fff;
              }

              @include transition(all 0.3s ease-in-out);
              @include mob {
                top: 0;
                border-radius: $m33px $m33px 0px 0px;
                font-size: $m13px;
                line-height: $m16px;
              }
            }
            @include desk {
              &:hover .bio {
                top: 0%;
              }
            }
          }
        }
      }
    }
  }
}

footer {
  position: relative;
  z-index: 4;
  height: $d797px;
  border-radius: $d47px $d47px 0px 0px;
  background: #f9dddd;
  margin-top: -$d15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include mob {
    margin-top: 0;
    height: auto;
    border-radius: 0;
  }
  .gradient {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    height: $d465px;
    width: 100%;
    background: linear-gradient(180deg, rgba(249, 221, 221, 0.35) 0%, rgba(244, 102, 102, 0.35) 67.19%);
    @include mob {
      height: $m439px;
      bottom: $m160px;
    }
  }
  .partners {
    mix-blend-mode: multiply;
    position: relative;
    z-index: 1;
    @include mob {
      height: $m333px;
    }
    h2 {
      color: #000;
      text-align: center;
      font-family: 'Heading Now';
      font-size: $d20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.04166666667vw;
      text-transform: uppercase;
      margin-bottom: $d188px;
      @include mob {
        margin-top: $m33px;
        margin-bottom: $m86px;
        font-size: $m13px;
        letter-spacing: -0.104vw;
      }
    }
    .list {
      height: $d115px;
      margin-bottom: $d169px;
      @include mob {
        height: $m107px;
        margin-bottom: 0;
      }
      .img {
        display: flex;
        align-items: center;
        padding-left: $d111px;
        img {
          max-height: $d115px;
          @include mob {
            max-height: $m107px;
            max-width: $m286px;
          }
        }
        @include mob {
          width: 100vw;
          min-width: 100vw;
          padding-left: 0;
          justify-self: center;
          align-self: center;
        }
        @include desk {
          &.sbp img {
            width: $d200px;
          }
          &.mgimo img {
            width: $d167px;
          }
          &.moscvith img {
            width: $d186px;
          }
          &.wink img {
            width: $d303px;
          }
          &.media-telecom img {
            width: $d188px;
          }
          &.start img {
            width: $d215px;
          }
          &.cdk img {
            width: $d301px;
          }

          &.nmg img {
            width: $d301px;
          }
          &.gpm img {
            width: $d301px;
          }
          &.matreshka img {
            width: $d301px;
          }
        }
      }
    }
  }
  .footer {
    position: relative;
    z-index: 2;
    height: $d183px;
    border-radius: $d46px $d46px 0px 0px;
    background: #fff;
    @include mob {
      height: $m449px;
      border-radius: $m33px $m33px 0px 0px;
    }
    .logo {
      width: $d90px;
      height: $d83px;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      background-image: url(../images/footer_logo.png);
      position: absolute;
      left: $d34px;
      top: $d36px;
      @include mob {
        width: $m54px;
        height: $m50px;
        left: $m161px;
        top: $m371px;
      }
    }
    .button {
      display: block;
      position: absolute;
      top: $d40px;
      right: $d34px;
      width: $d451px;
      height: $d45px;

      border-radius: $d100px;
      background: #f46666;
      border: $d3px solid #f46666;

      color: #fff;
      text-align: center;
      font-family: 'Heading Now';
      font-size: $d16px;
      font-style: normal;
      font-weight: 400;
      line-height: $d45px;
      letter-spacing: -0.04166666667vw;
      text-transform: uppercase;
      text-decoration: none;
      @include desk {
        &:hover {
          background: #fff;
          color: #f46666;
        }
      }

      @include mob {
        width: $m334px;
        height: $m32px;
        right: $m20px;
        top: $m17px;

        font-size: $m12px;
        line-height: normal;
        letter-spacing: -0.096vw;
        line-height: $m32px;
      }
    }
    .contacts {
      text-align: right;
      position: absolute;
      right: $d35px;
      top: $d106px;
      @include mob {
        right: $m46px;
        top: $m64px;
        left: $m46px;
        text-align: center;
        line-height: $m16px;
      }
      .mail {
        display: block;
        color: #000;
        font-family: 'Heading Now';
        font-size: $d16px;
        font-style: normal;
        font-weight: 400;
        line-height: $d25px;
        letter-spacing: -0.04166666667vw;
        text-transform: uppercase;
        @include mob {
          font-size: $m11px;
          line-height: $m20px; /* 181.818% */
          letter-spacing: -0.088vw;
        }
      }
      .phone {
        color: #000;
        font-family: 'Heading Now';
        font-size: $d16px;
        font-style: normal;
        font-weight: 400;
        line-height: $d25px;
        letter-spacing: $d1px;
        text-transform: uppercase;
        @include mob {
          font-size: $m11px;
          line-height: $m20px; /* 181.818% */
          letter-spacing: 0.14666666667vw;
        }
      }
    }
    .menu {
      display: block;
    }
    .menu a {
      color: #000;
      font-family: Steinbeck;
      font-size: $d20px;
      font-style: normal;
      font-weight: 400;
      line-height: $d39px; /* 170% */
      letter-spacing: 0.02777777778vw;
      text-transform: uppercase;
      cursor: pointer;
      display: block;
      @include mob {
        text-align: center;
        font-size: $m15px;
        font-weight: 400;
        line-height: $m34px;
        letter-spacing: 0.08vw;
        width: $m150px;
        margin: 0 auto;
      }
    }
    .menu1 {
      position: absolute;
      left: $d259px;
      top: $d27px;
      @include mob {
        left: 0;
        top: $m127px;
        right: 0;
        text-align: center;
      }
    }
    .menu2 {
      position: absolute;
      left: $d506px;
      top: $d27px;
      @include mob {
        display: none;
      }
    }
  }
}
